@import '../../node_modules/rsuite/styles/index.less';

// variables https://github.com/rsuite/rsuite/blob/main/src/styles/variables.less

// Enable dark mode
@enable-dark-mode: false;

// Enable high contrast mode
@enable-high-contrast: false;

@primary-color: #78ABC6;


#root {
  height: 100vh;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px;
}

.m-10 {
  margin: 10px;
}

.mt-5 {
  margin-top: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.pt-20 {
  padding-top: 20px;
}

.h100p {
  height: 100%;

  .rs-panel-body {
    height: 100%;
  }
}

.sidebar-collapsed {
  flex: 0 0 56px !important;
  width: 56px !important;
}

.rs-sidenav-header {
  background-color: #78ABC6;
  height: 56px;
}

.sidenav-header-logo {
  color: white;
  font-size: 18px;
  margin-left: 20px;
}

.hide-menu-button {
  color: white;
  font-size: 20px;
  margin-top: 7px;
  margin-left: 10px;
}

.mall-icon {
  background-image: url("/public/mall.svg");
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

.kauf-icon {
  background-image: url("/public/kauf.svg");
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

.alza-icon {
  background-image: url("/public/alza_cz.svg");
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

.allegro-icon {
  background-image: url("/public/allegro.png");
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
}

.main-container {
  height: 100%;

  .rs-sidenav {
    height: 100%;
  }
}

.main-section {
  overflow: hidden;

  main {
    overflow: auto;

    .rs-panel {
      overflow: revert;
    }
  }
}

.input-number-no-arrow {
  .rs-input-number-btn-group-vertical {
    display: none;
  }
}

.loading-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background-color: gray;
  opacity: 0.5;
}

.copyright {
  margin: 5px;
}

.upload-csv-container {
  padding: 5px;
  border: 1px solid gray;
}

.form-box {
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-bottom: 3px;

    & > .form-box-label {
      flex: 0 1 170px;
      width: 170px;
      min-height: 20px;
      line-height: 1.42857143;
      text-align: right;
      margin-right: 12px;
      padding: 3px 0;
    }

    & > .form-box-content {
      flex: 1 1 auto;
    }
  }
}

.uploader {
  display: inline-block;
  width: 100%;
  height: 100px;
  border: 1px dashed @primary-color;
  text-align: center;
  padding: 10px;
}

.select-all-container {
  padding: 10px 2px;
  border-top: 1px solid #e5e5e5;
}

.rs-table-row-header {
  background-color: @primary-color !important;

  .rs-table-cell {
    background-color: @primary-color !important;
    border-bottom-color: @primary-color !important;;
    color: white;
  }
}

.rs-table-row:nth-child(2n) {
  background-color: #DAE3ED !important;;

  .rs-table-cell {
    background-color: #DAE3ED !important;
    border-bottom-color: #DAE3ED !important;;
    //color: white;
  }
}

.rs-table-cell-header-icon-sort {
  color: white;
}

.table-header-checkbox {
  .rs-checkbox-wrapper::before {
    border: 1px solid red;
  }
}

.nav-large {
  font-size: 20px;
}

.order-status {
  color: white;
  font-weight: bold;
  width: 100px;

  &-OPEN:extend(.order-status) {
    background-color: #BFBFBF;
  }

  &-SHIPPING:extend(.order-status) {
    background-color: #64C4D0;
  }

  &-SHIPPED:extend(.order-status) {
    background-color: #9CBA48;
  }

  &-DELIVERED:extend(.order-status) {
    background-color: #488E7B;
  }

  &-CANCELLED:extend(.order-status) {
    background-color: #C84C44;
  }

  &-RETURNED:extend(.order-status) {
    background-color: #F29043;
  }

  &-RESERVATION:extend(.order-status) {
    background-color: #54708E;
  }
}

.order-status-radio {
  & > div {
    width: 150px;
    border-radius: 10px;
  }
}


.order-table-container {
  height: calc(100% - 160px);

  &-filter {
    height: calc(100% - 342px);
  }
}

.table-container {
  height: calc(100% - 50px);
}

.order-preview-container {
  max-height: 175px;
  overflow: auto;

  border: 1px solid lightgray;
}


.order-preview-table {
  & thead {
    & > tr > td, th {
      padding: 2px 10px;
      background-color: @primary-color;
      color: white;
    }
  }

  & tbody > tr > td {
    padding: 2px 10px;
  }
}

.print-order-container {
  height: calc(100vh - 115px);
}

.print-order-table-container {
  height: calc(100% - 180px);
}

.sticky-header {
  thead td, th {
    position: sticky;
    top: 0
  }
}

.success-tooltip {
  background-color: #6bcc6b;
  color: black;
}


.flex-box-filters {
  & > div {
    margin-top: 5px;
  }

  & > div:nth-child(2n+1) {
    text-align: right;
  }
}

.error-message-tooltip {
  max-width: 80vw;
}

.product-table-container {
  height: calc(100% - 160px);

  &-filter {
    height: calc(100% - 334px);
  }
}

.product-status {
  color: white;
  font-weight: bold;
  width: 110px;

  &-MPI_ONLY:extend(.product-status) {
    background-color: #BFBFBF;
  }

  &-DRAFT:extend(.product-status) {
    background-color: #BFBFBF;
  }

  &-TO_BE_PUBLISHED:extend(.product-status) {
    background-color: #64C4D0;
  }

  &-UPDATE_PENDING:extend(.product-status) {
    background-color: #64C4D0;
  }

  &-TO_BE_DELETED:extend(.product-status) {
    background-color: #64C4D0;
  }

  &-TO_BE_ACTIVATED:extend(.product-status) {
    background-color: #64C4D0;
  }


  &-PUBLISHED_TEST:extend(.product-status) {
    background-color: #9CBA48;
  }

  &-PUBLISHED_LIVE:extend(.product-status) {
    background-color: #488E7B;
  }

  &-UPDATE_FAILED:extend(.product-status) {
    background-color: #F29043;
  }

  &-DELETION_FAILED:extend(.product-status) {
    background-color: #C84C44;
  }

  &-IMPORT_FAILED:extend(.product-status) {
    background-color: #C84C44;
  }

  &-INTERNAL_ERROR:extend(.product-status) {
    background-color: #C84C44;
  }

  &-DELETION_FAILED:extend(.product-status) {
    background-color: #C84C44;
  }
}

.update-pending {
  background-color: var(--rs-btn-default-active-bg);
}

.order-serial-numbers-table {
  text-align: left;
}

.order-serial-numbers-table th {
  padding-right: 15px;
}

.order-serial-numbers-table td {
  padding-right: 15px;
  padding-bottom: 10px;
}

#allegro-mapped-delivery-methods-message {
  .rs-message-container {
    padding: 10px;
  }
}

.rs-table-cell-content {
  display: block !important;
  text-align: center !important;
}